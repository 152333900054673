.demo-mode-heading {
    display: none;
}

#connect li:nth-child(2) .button.user {
    background: white;
    border: none;
    box-shadow: none !important;
}

@labelsFontSize: 15px;
@valuesFontSize: 16px;
@fileComponentFontSize: 14px;
@homePageLinkColor: #389f8f;

body {
    &.modern-theme {
        #base {
            #content {
                .dashboard {
                    #tab-applications {
                        #applications-list {
                            table {
                                &.table {
                                    tbody {
                                        border-radius: 6px;
                                        box-shadow: 0 0 0 1px #E0E4EB;

                                        tr {
                                            &:nth-last-child(2) {
                                                td {
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .apps-pagination {
                                top: 2px;
                                position: relative;
                            }
                        }
                    }
                    .doc-preview-modal {
                        .doc-preview-modal-grid {
                            .doc-preview-modal-header {
                                .doc-preview-modal-opts {
                                    .doc-preview-modal-opt-item {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .homepage-link-text {
                        display: flex;
                        align-items: baseline;
                        font-size: 18px;
                        color: @homePageLinkColor;

                        i {
                            color: @homePageLinkColor;
                        }
                    }
                }
                .section-body {
                    .contain-lg {
                        .formio-form {
                            .wizard-page .formio-component,
                            .formio-component {
                                label,
                                label.col-form-label {
                                    font-size: @labelsFontSize !important;
                                }
                                input,
                                textarea {
                                    &.form-control {
                                        font-size: @valuesFontSize;
                                    }
                                }
                                .choices__item {
                                    span {
                                        font-size: @valuesFontSize;
                                    }
                                }
                                &.formio-component-file {
                                    ul.list-group li.list-group-item .row,
                                    .fileSelector,
                                    .fileSelector a {
                                        font-size: @fileComponentFontSize !important;
                                    }
                                }
                                &.formio-component-editgrid {
                                    .list-group .list-group-item.list-group-header .row > div {
                                        font-size: @labelsFontSize !important;
                                    }
                                    .list-group .list-group-item.list-group-header ~ .list-group-item > .row > div,
                                    .list-group .list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div {
                                        font-size: @valuesFontSize !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .new-formio-file-history {
                    .task-owner {
                        display: none;
                    }
                }
            }
        }
    }
}